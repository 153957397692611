/* Because we build with gulp-uglify, don't use ES6 features here */
/* TODO: Swap out gulp-uglify for babel or something more modern! */
function createTippyTooltips() {
  tippy('[data-tooltip]',
    {
      animation: 'fade',
      placement: 'right',
      arrowType: 'round',
      theme: 'material',
      multiple: true,
      duration: 120,
      delay: [80, 40],
      content: function (e) {
        return $(e).data('tooltip');
      }
    });
  tippy('[title]',
    {
      animation: 'shift-away',
      placement: 'top',
      theme: 'light-border',
      multiple: true,
      duration: 180,
      delay: [110, 50],
      content: function (e) {
        return $(e).attr('title');
      },
      onShow: function (e) {
        var savedTitle = $(e.reference).attr('title');
        $(e.reference).data('tippy-save-title', savedTitle);
        $(e.reference).attr('title', null);
        return true;
      },
      onHidden: function (e) {
        var savedTitle = $(e.reference).data('tippy-save-title');
        $(e.reference).attr('title', savedTitle);
        $(e.reference).data('tippy-save-title', null);
      }
    });
  tippy('.popup-password',
    {
      animation: 'perspective',
      placement: 'top',
      theme: 'material',
      multiple: true,
      duration: 210,
      trigger: 'focus',
      hideOnClick: false,
      allowHTML: true,
      content: function (/* ReSharper disable once UnusedParameter */ e) {
        return '<ul class="tippy-password-requirements">' +
          '<li>At least 8 characters minimum</li>' +
          '<li>At least one uppercase character</li>' +
          '<li>At least one lowercase character</li>' +
          '<li>At least one number</li>' +
          '<li>At least one special character</li>' +
          '</ul>';
      }
    });
}

function createPopperBlock(triggerId, groupId) {
  const trigger = document.querySelector(triggerId);
  const group = document.querySelector(groupId);

  const popperInstance = Popper.createPopper(
    trigger,
    group,
    {
      placement: 'bottom-end',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
        {
          name: 'arrow',
          options: {
            padding: 5, // 5px from the edges of the popper
          }
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['left', 'top'],
          },
        }
      ]
    }
  );

  function showPopper(e) {
    group.setAttribute('data-show', '');

    // Enable the event listeners
    popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        { name: 'eventListeners', enabled: true },
      ],
    }));

    trigger.removeEventListener('click', showPopper);
    trigger.addEventListener('click', hidePopper);
    document.addEventListener('click', hidePopper);

    popperInstance.update();

    e.preventDefault();
    e.stopPropagation();
  }

  function hidePopper(e) {
    if (group.contains(e.srcElement))
      return;

    group.removeAttribute('data-show');

    // Disable the event listeners
    popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        { name: 'eventListeners', enabled: false },
      ],
    }));

    document.removeEventListener('click', hidePopper);
    trigger.removeEventListener('click', hidePopper);
    trigger.addEventListener('click', showPopper);
  }

  trigger.addEventListener('click', showPopper);
}
